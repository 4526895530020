<template>
	<div>
		<page-title></page-title>
		<b-card class="mt-1 position-relative" no-body style="height: calc(100vh - 13rem)">
			<b-embed v-if="!!projectVr" :src="src" allowfullscreen class="rounded" scrolling="no" type="iframe"></b-embed>
		</b-card>
	</div>
</template>

<script>
	import { computed, reactive, toRefs } from "@vue/composition-api";
	import { routerParams } from "@/libs/utils/routerParams";
	import PageTitle from "@/views/layout/PageTitle";
	import { iframeSrc } from "@/libs/utils/iframeSrc";

	export default {
		name: "ProjectIframe",
		components: {
			PageTitle,
		},
		setup() {
			const event = reactive({
				project_id: computed(() => {
					return routerParams("project_id");
				}),
				projectVr: computed(() => {
					const projectInfo = JSON.parse(localStorage.getItem("project-selected"));
					return projectInfo.vr;
				}),
				src: computed(() => {
					return iframeSrc(event.projectVr);
				}),
			});

			return {
				...toRefs(event),
			};
		},
	};
</script>

<style scoped></style>
